import React from 'react';
import Helmet from "react-helmet";
import styled from "styled-components";

const LayoutStyles = styled.div`
  //scroll-behavior: smooth;
  //position: relative;
  //overflow-x: hidden;
  //&:before,
  //&:after {
  //	content: '';
  //	position: absolute;
  //	z-index: -1;
  //	top: -50%;
  //	width: 200%;
  //	height: 100%;
  //	transform: skewX(60deg);
  //}
  //
  //&:after {
  //	background-color: hsla(204deg, 68%, 93%, 100%);
  //}
  //&:before {
  //	background-color: hsla(56deg, 89%, 96%, 50%);
  //	transform: skewX(135deg);
  //	top: -55%;
  //	width: 340vw;
  //	height: 155%;
  //}
`;

const schema = {
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  name: "Hall04 et Cie",
  image: "../images/i<3hall04-tee@2x.png",
  url: "https://hall04.net",
  telephone: "0033687247084",
  address: {
    "@type": "PostalAddress",
    addressCountry: "FR",
  },
  sameAs: [
    "https://www.facebook.com/Hall04/",
    "https://www.instagram.com/Hall04_skateparks/",
  ],
};
export default function Layout({ children }) {
  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "fr",
        }}
      >
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <LayoutStyles>{children}</LayoutStyles>
    </React.Fragment>
  );
}
